/* styles.css */
@import url('https://fonts.googleapis.com/css2?family=Lexend+Zetta&display=swap');

/* header {
    font-family: 'Lexend Zetta', sans-serif;
} */


.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px; /* Space between text and image */
    margin-bottom: 40px;
    padding: 0 100px;
}


.keen-slider__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    flex: 0 0 auto;
    width: 100%; /* Ensure each slide takes full width */
}

/* Main Client Carousel */
.client-carousel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-auto-rows: 120px;
    gap: 10px;
}

.client-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-circle img {
    width: 100%;
    height: auto;
}

/* Technology, Language, Source Carousel */
.tech-carousel {
    display: flex;
    gap: 10px;
    overflow: auto;
    margin-top: 20px;
}

.tech-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tech-circle {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    overflow: hidden;
    margin: 0 5px;
}


.keen-slider__slide_two {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    flex: 0 0 auto;
    width: 50px; /* Ensure each slide takes full width */
}




/* Differentiate the bubbles by type */
.tech-circle.technology {
    border: 2px solid blue;
}

.tech-circle.language {
    border: 2px solid green;
}

.tech-circle.source {
    border: 2px solid red;
}


/* for inverting the boost gif to make it black */
.invert-gif {
    filter: invert(1); /* Invert colors */
}



/* for game  */

/* src/components/RunnerGame.css */
.game-container {
    position: relative;
    width: 800px;
    height: 200px;
    background-color: #f0f0f0;
    overflow: hidden;
    border: 2px solid #ccc;
    margin: 0 auto;
  }
  
  .distance {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .game-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    font-weight: bold;
    color: red;
  }
  
  .reset-btn {
    display: inline-block;             /* Allows margin auto to work with centering */
    margin: 20px auto 0;               /* Centers button horizontally */
    padding: 10px 30px;                /* Adds padding for pill shape */
    font-size: 16px;
    font-weight: bold;                 /* Optional: makes the text bold */
    color: #fff;                       /* Text color */
    background-color: #ff0000;         /* Background color for the button */
    border: none;                      /* Removes default button border */
    border-radius: 50px;               /* Creates the pill shape */
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);  /* Subtle shadow for depth */
    transition: background-color 0.3s ease;      /* Smooth transition for hover */
}
  

/* header css */
/* Header base styling */
.navbar-pill {
    background-color: #FFBD59;
    border-radius: 50px;
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(255, 189, 89, 0.6),
                0 0 20px rgba(255, 189, 89, 0.4),
                0 0 30px rgba(255, 189, 89, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    /* flex-wrap: wrap; Allow wrapping for content */
    max-width: 95%;
    overflow: hidden;
    flex-wrap: nowrap; 

    margin: 0 auto;
    white-space: nowrap; /* Add this */
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
}


.navbar-pill:hover {
    box-shadow: 0 0 15px rgba(255, 189, 89, 0.8),
                0 0 25px rgba(255, 189, 89, 0.6),
                0 0 35px rgba(255, 189, 89, 0.4);
}

/* Icon Link Base Styling */
.navbar-pill .icon-link {
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px; /* Reduced font size */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px; /* Reduced size */
    height: 35px; /* Reduced size */
    border-radius: 50%;
    transition: all 0.3s ease;
}

.navbar-pill .icon-link:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 1);
}

.navbar-brand {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
    white-space: nowrap; /* Prevent name from wrapping */
}

/* Responsive Media Queries */
@media (max-width: 1200px) {
    .navbar-pill {
        padding: 8px 18px;
    }
    .navbar-pill .icon-link {
        font-size: 18px;
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 992px) {
    .navbar-pill {
        padding: 6px 16px;
    }
    .navbar-pill .icon-link {
        font-size: 16px;
        width: 36px;
        height: 36px;
    }
}

@media (max-width: 768px) {
    .navbar-pill {
        padding: 5px 14px;
    }
    .navbar-pill .icon-link {
        font-size: 15px;
        width: 34px;
        height: 34px;
    }
}

@media (max-width: 576px) {
    .navbar-pill {
        padding: 4px 8px; /* Even less padding for small screens */
    }
    .navbar-pill .icon-link {
        font-size: 14px;
        width: 28px;
        height: 28px;
    }
    .navbar-brand {
        font-size: 18px; /* Slightly smaller for extra-small screens */
    }
}




/* dashboard css */

/* .container {
    max-width: 1200px;
  }
   */

  .dashboard-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #333;
    font-size: 2.5rem;
  }
  
  .dashboard-description {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    color: #555;
  }
  
  .card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #444;
    font-size: 1.25rem;
  }
  
  /* iframe {
    border: none;
    border-radius: 4px;
  } */
  


  /* styles for medium article 'embed' */

  .article-embed {
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .article-embed:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .article-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
  }
  
  .article-image-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .article-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .article-content {
    padding: 16px;
  }
  
  .article-title {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 8px;
    color: #333;
  }
  
  .article-description {
    font-size: 1rem;
    margin-bottom: 12px;
    color: #666;
  }
  
  .read-more {
    font-weight: bold;
    color: #00ab6b;
  }
  